import callApi from "./index";
import URL from "../constants/urlPaths";

export default {
  getTemplatesQuickList(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATES_QUICK_LIST + url,
      })
      .then((res) => res.data);
  },

  editQuickTemplate(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.TEMPLATES_QUICK_EDIT + url,
        data,
      })
      .then((res) => res.data);
  },

  getTemplatesList(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATES_MAIN_URL + url,
      })
      .then((res) => res.data);
  },

  getTemplatesStatistics(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATES_STATISTICS + url,
      })
      .then((res) => res.data);
  },

  getTemplatesListFilter(url = "", data) {
    return callApi.request({
      method: "POST",
      url: URL.TEMPLATES_FILTER + url,
      data,
    });
  },

  createTemplate(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.TEMPLATES_MAIN_URL,
        data,
      })
      .then((res) => res.data);
  },

  editTemplate(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.TEMPLATES_MAIN_URL + url,
        data,
      })
      .then((res) => res.data);
  },

  getItemTemplate(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATES_MAIN_URL + url,
      })
      .then((res) => res.data);
  },

  deleteTemplate(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.TEMPLATES_MAIN_URL + url,
      })
      .then((res) => res.data);
  },

  templateCheckSeo(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATE_CHECK_SEO + url,
      })
      .then((res) => res.data);
  },

  templateImagesUpload(data) {
    return callApi
      .request({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "POST",
        url: URL.TEMPLATE_IMAGES_UPLOAD,
        data,
      })
      .then((res) => res.data);
  },

  templateImagesDelete(data) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.TEMPLATE_IMAGES_DELETE,
        data,
      })
      .then((res) => res.data);
  },

  templateImagesPreviewUpload(data) {
    return callApi
      .request({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "POST",
        url: URL.TEMPLATE_IMAGES_PREVIEW,
        data,
      })
      .then((res) => res.data);
  },

  templateImagesPreviewDelete(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.TEMPLATE_IMAGES_DELETE + url,
      })
      .then((res) => res.data);
  },

  templateImagesSliderUpload(data) {
    return callApi
      .request({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "POST",
        url: URL.TEMPLATE_IMAGES_SLIDER,
        data,
      })
      .then((res) => res.data);
  },

  templateImagesSliderDelete(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.TEMPLATE_IMAGES_SLIDER + url,
      })
      .then((res) => res.data);
  },

  templateInstructionUpload(data) {
    return callApi
      .request({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "POST",
        url: URL.TEMPLATE_INSTRUCTION_UPLOAD,
        data,
      })
      .then((res) => res.data);
  },

  templateInstructionDelete(data) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.TEMPLATE_INSTRUCTION_DELETE,
        data,
      })
      .then((res) => res.data);
  },

  templateSourceFileUpload(data) {
    return callApi
      .request({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "POST",
        url: URL.TEMPLATES_SOURCE_FILE_LOAD,
        data,
      })
      .then((res) => res.data);
  },

  templateSourceFileDelete(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.TEMPLATES_SOURCE_FILE_LOAD + url,
      })
      .then((res) => res.data);
  },

  templateVideoUpload(data) {
    return callApi
      .request({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "POST",
        url: URL.TEMPLATE_VIDEO_UPLOAD,
        data,
      })
      .then((res) => res.data);
  },

  templateVideoDelete(data) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.TEMPLATE_VIDEO_UPLOAD,
        data,
      })
      .then((res) => res.data);
  },

  templateImageSizes() {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATES_IMAGE_SIZES,
      })
      .then((res) => res.data);
  },

  transferFile(data) {
    return callApi
      .request({
        method: "POST",
        url: "https://admin-ftp.elegantflyer.com/api/uploads/transferFileToSpace",
        data,
      })
      .then((res) => res.data);
  },

  getFile(data) {
    return callApi
      .request({
        method: "POST",
        url: "https://admin-ftp.elegantflyer.com/api/uploads/getFileFromSpace",
        data,
      })
      .then((res) => res.data);
  },

  // Content Plans

  getPlansList(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.CONTENT_PLANS + url,
      })
      .then((res) => res.data);
  },

  createPlan(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.CONTENT_PLANS,
        data,
      })
      .then((res) => res.data);
  },

  getPlanItems(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.CONTENT_PLAN_ITEMS + url,
      })
      .then((res) => res.data);
  },

  createPlanItem(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.CONTENT_PLAN_ITEM,
        data,
      })
      .then((res) => res.data);
  },

  editPlanItem(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.CONTENT_PLAN_ITEM + url,
        data,
      })
      .then((res) => res.data);
  },

  deletePlanItem(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.CONTENT_PLAN_ITEM + url,
      })
      .then((res) => res.data);
  },

  // Keywords Plans

  getKeywordsList(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.KEYWORDS + url,
      })
      .then((res) => res.data);
  },

  createKeywords(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.KEYWORDS,
        data,
      })
      .then((res) => res.data);
  },

  createKeywordsArray(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.KEYWORDS_ARRAY,
        data,
      })
      .then((res) => res.data);
  },

  getKeywordsItem(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.KEYWORDS + url,
      })
      .then((res) => res.data);
  },

  editKeywords(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.KEYWORDS + url,
        data,
      })
      .then((res) => res.data);
  },

  deleteKeywords(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.KEYWORDS + url,
      })
      .then((res) => res.data);
  },

  getKeywordsListFilter(url = "", data) {
    return callApi.request({
      method: "POST",
      url: URL.KEYWORDS + "/filter" + url,
      data,
    });
  },

  getAiRequirements(url) {
    return callApi.request({
      method: "GET",
      url: URL.KEYWORD_REQUIREMENT + url,
    });
  },

  templatesGetByUrl(url) {
    return callApi.request({
      method: "GET",
      url: URL.TEMPLATES_GET_BY_URL + url,
    });
  },

  // Template Layout

  createTemplateLayout(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.TEMPLATES_LAYOUT,
        data,
      })
      .then((res) => res.data);
  },

  getTemplateLayout(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATES_LAYOUT + url,
      })
      .then((res) => res.data);
  },

  getTemplateLayoutById(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.TEMPLATES_LAYOUT + "/get-by-template" + url,
      })
      .then((res) => res.data);
  },

  editTemplateLayout(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.TEMPLATES_LAYOUT + url,
        data,
      })
      .then((res) => res.data);
  },

  deleteTemplateLayout(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.TEMPLATES_LAYOUT + url,
      })
      .then((res) => res.data);
  },

  //Holidays

  getHolidaysList(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.HOLIDAYS + url,
      })
      .then((res) => res.data);
  },

  createHolidays(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.HOLIDAYS,
        data,
      })
      .then((res) => res.data);
  },

  editHolidays(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.HOLIDAYS + url,
        data,
      })
      .then((res) => res.data);
  },

  getHolidaysItems(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.HOLIDAYS + url,
      })
      .then((res) => res.data);
  },

  deleteHolidays(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.HOLIDAYS + url,
      })
      .then((res) => res.data);
  },

  getPaper(data) {
    return callApi
      .request({
        method: "POST",
        baseURL: "https://api.thegoodocs.com",
        url: "/v1/ai-image-tools/papers/lined",
        data,
      })
      .then((res) => res.data);
  },
};
